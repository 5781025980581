import React, { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { CSSTransition } from 'react-transition-group';

import { Dropdown, LangSelector, Wrapper, ArrowWrapper, Arrow } from '@components/Navbar/LanguageSelector/styles';
import ArrowImage from '@assets/images/chevron.svg';
import ToggableList from '@components/ToggableList';

function LanguageSelector() {
  const { locales, asPath, locale } = useRouter();
  const ref = useRef();

  const [isVisible, setIsVisible] = useState(false);

  return (
    <LangSelector ref={ref} onClick={() => setIsVisible(!isVisible)} role="button" aria-hidden="true">
      <Wrapper>
        <span>{locale}</span>
        <ArrowWrapper>
          <Arrow src={ArrowImage} className={isVisible && 'active'} width="100%" height="100%" />
        </ArrowWrapper>
      </Wrapper>

      <CSSTransition in={isVisible} timeout={300} classNames="slide-in" unmountOnExit>
        <ToggableList element={ref} setVisibility={setIsVisible}>
          <Dropdown>
            {locales.map((langKey) => (
              <Link key={`lang-button_${langKey}`} href={asPath} locale={langKey}>
                {langKey}
              </Link>
            ))}
          </Dropdown>
        </ToggableList>
      </CSSTransition>
    </LangSelector>
  );
}

export default React.memo(LanguageSelector);
