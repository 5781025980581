import routesJSON from '@constants/routes.json';

type FunctionRoute = (p: string | number) => string;

interface Routes {
  homepage?: string;
  student?: string;
  employer?: string;
  employerDetails?: FunctionRoute;
  internship?: string;
  contact?: string;
  signin?: string;
  signup?: string;
  passwordReset?: string;
  passwordChange?: string;
  offers?: string;
  offer?: FunctionRoute;
  profile?: string;
  accountConfirmation?: string;
  acceptTerms?: string;
}

const routes: Routes = {};

Object.keys(routesJSON).forEach((key) => {
  let route = routesJSON[key].source;
  if (route.includes(':')) {
    const [r] = route.split(':');
    route = (p: string | number) => `${r}${p}`;
  }

  routes[key] = route;
});

export default routes;
