import React from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@components/Button';
import routes from '@utils/routes';

import BottomBannerContainer from '@components/BottomBanner/styles';

import TextHeader, { TAG, SIZE, WEIGHT } from '@components/TextHeader';

function BottomBanner() {
  const { t } = useTranslation('bottomBanner');

  return (
    <BottomBannerContainer as="section">
      <TextHeader size={SIZE.MD} tag={TAG.H4} weight={WEIGHT.NORMAL}>
        {t('header')}
      </TextHeader>

      <Button href={routes.offers}>{t('button')}</Button>
    </BottomBannerContainer>
  );
}
export default BottomBanner;
