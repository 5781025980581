import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { ContentWrapper, fontSize } from '@styles/globalStyles';

const BottomBanner = styled(ContentWrapper)`
  width: 100%;
  height: 582px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: unset;

  h4 {
    font-size: ${fontSize(36)};
    max-width: 860px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 72px;
  }

  @media only screen and ${breakpoint.xl} {
    height: 470px;

    h4 {
      font-size: ${fontSize(32)};
      margin-bottom: 56px;
    }
  }

  @media only screen and ${breakpoint.lg} {
    height: 400px;

    h4 {
      font-size: ${fontSize(28)};
      margin-bottom: 42px;
    }
  }

  @media only screen and ${breakpoint.md} {
    height: 320px;

    h4 {
      font-size: ${fontSize(24)};
      margin-bottom: 28px;
    }
  }
`;

export default BottomBanner;
