import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import routes from '@utils/routes';
import menuButton from '@assets/images/ic-mobile-menu.svg';

import Button, { BUTTON_THEME } from '@components/Button';
import MobileMenu from '@components/Navbar/MobileMenu';
import UserMenu from '@components/Navbar/UserMenu';

import LanguageSelector from '@components/Navbar/LanguageSelector';
import SearchBar from '@components/Autocomplete/SearchBar';

import { useAppContext } from '@context/AppContext';

import FontScaleIcon from '@assets/images/font-scale.svg';
import HighContrastIcon from '@assets/images/high-contrast.svg';
import SearchIcon from '@assets/images/search.svg';
import CloseIcon from '@assets/images/icons/close-white.svg';
import closeButton from '@assets/images/ic-close.svg';
import HighContrastFontScaleIcon from '@assets/images/highcontrast-font.svg';
import HighContrastSearchIcon from '@assets/images/highcontrast-search.svg';
import HighContrastClose from '@assets/images/highcontrast-close.svg';

import {
  PageHeader,
  Content,
  LogoContainer,
  Menu,
  MenuItem,
  Buttons,
  DropdownMenu,
  DropmenuItem,
  Grow,
  MobileButtonsContainer,
  SearchBarContainer,
  SearchBarContent,
} from '@components/Navbar/styles';

import Logo from '@assets/images/logo-lodz-colour.svg';
import useBreakpoint from '@hooks/useBreakpoint';

type NavbarProps = {
  items: MainNavigation;
};

const HIGHCONTRAST_ENABLED = true;

function Navbar({ items }: NavbarProps) {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const { t } = useTranslation('navbar');
  const { switchFontScale, switchTheme, isAuthenticated, useHighContrastTheme: isHighContrast } = useAppContext();
  const { asPath } = useRouter();

  const [isMobileMenuOpen, setOpenMenuMobile] = useState(false);

  const handlerMenuMobile = useCallback(() => {
    setOpenMenuMobile(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  const UserSection = useMemo(() => {
    if (!isAuthenticated) {
      return (
        <Button href={routes.signin} buttonTheme={BUTTON_THEME.SECONDARY}>
          {t('Signin')}
        </Button>
      );
    }

    return <UserMenu />;
  }, [isAuthenticated, t]);

  const [searchBar, setSearchBar] = useState(false);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.target.id !== 'showSearchBar') {
            setSearchBar(false);
          }
        }
      }
      // Bind the event listener
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <PageHeader>
      <Content>
        <Link href={routes.homepage}>
          <LogoContainer href={routes.homepage} onClick={() => setOpenMenuMobile(false)}>
            <Image src={Logo} width="100%" height="100%" />
          </LogoContainer>
        </Link>
        <Menu>
          {Object.keys(items).map((key) => (
            <MenuItem key={key} className="menu-item-link" active={asPath === routes[key]}>
              {key === 'student' && (
                <Link href="/">
                  <a href="/" title={t(key)}>
                    {t(key)}
                  </a>
                </Link>
              )}
              {key !== 'student' && (
                <Link href={routes[key]}>
                  <a href={routes[key]} title={t(key)}>
                    {t(key)}
                  </a>
                </Link>
              )}

              {items[key].length > 0 ? (
                <DropdownMenu>
                  {items[key].map((item: NavigationItem) => (
                    <DropmenuItem key={item.id}>
                      <Link href={item.link}>
                        <a href={item.link} target={item.target} title={item.title}>
                          {item.title}
                        </a>
                      </Link>
                    </DropmenuItem>
                  ))}
                </DropdownMenu>
              ) : null}
            </MenuItem>
          ))}
        </Menu>
        <Grow />
        <Buttons>
          <button type="button" onClick={switchFontScale}>
            <Image src={isHighContrast ? HighContrastFontScaleIcon : FontScaleIcon} alt="vis" />
          </button>
          {HIGHCONTRAST_ENABLED ? (
            <button type="button" onClick={switchTheme}>
              <Image src={HighContrastIcon} alt="font" />
            </button>
          ) : null}
          <button type="button" className="search-button" onClick={() => setSearchBar(!searchBar)}>
            {searchBar ? (
              <Image
                id="showSearchBar"
                src={isHighContrast ? HighContrastClose : CloseIcon}
                alt="search"
                width={24}
                height={24}
              />
            ) : (
              <Image
                id="showSearchBar"
                src={isHighContrast ? HighContrastSearchIcon : SearchIcon}
                alt="search"
                width={40}
                height={40}
              />
            )}
          </button>
        </Buttons>
        <LanguageSelector />

        {UserSection}

        {matchBreakpoint(BREAKPOINTS.MD) ? (
          <>
            <MobileButtonsContainer>
              {isMobileMenuOpen ? (
                <Image src={closeButton} onClick={handlerMenuMobile} />
              ) : (
                <Image src={menuButton} onClick={handlerMenuMobile} />
              )}
            </MobileButtonsContainer>

            <MobileMenu items={items} openMenuMobile={isMobileMenuOpen} handlerMenuMobile={handlerMenuMobile} />
          </>
        ) : null}
      </Content>

      {searchBar && (
        <SearchBarContainer ref={wrapperRef}>
          <SearchBarContent className="search-bar">
            <SearchBar />
          </SearchBarContent>
        </SearchBarContainer>
      )}
    </PageHeader>
  );
}

export default React.memo(Navbar);
