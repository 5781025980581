import React, { ReactNode } from 'react';

import Navbar from '@components/Navbar';
// import Newsletter from '@components/Newsletter';
import BottomBanner from '@components/BottomBanner';
import Footer from '@components/Footer';

type Props = {
  children: ReactNode;
  mainNavigation: MainNavigation;
  footerNavigation: FooterNavigation;
};
function Layout({ children, mainNavigation, footerNavigation }: Props) {
  return (
    <>
      <Navbar items={mainNavigation} />
      <main>
        {children}
        <BottomBanner />
      </main>
      <Footer items={footerNavigation} />
    </>
  );
}

export default Layout;
