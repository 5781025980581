import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import BackgroundImage from '@assets/images/register-background.svg';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

export const LeftColumn = styled.div`
  background: #fff url(${BackgroundImage.src}) no-repeat 50% 50% / cover;
  width: 37.5%;
  height: 100%;
  flex: 0 0 37.5%;

  @media only screen and ${breakpoint.lg} {
    width: 45%%;
    flex: 0 0 45%;
  }

  @media only screen and ${breakpoint.md} {
    display: none;
  }
`;

export const ScrollableContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const RightColumn = styled.div`
  height: 100%;
  overflow-y: auto;
  background-color: #f6f7f8;
  // display: flex;
  /* align-items: center; */
  justify-content: center;
  flex: 1;
  padding: 120px 0 70px;

  @media only screen and ${breakpoint.md} {
    min-height: 600px;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 48px;
  right: 48px;
  width: 35px;
  height: 35px;
  display: block;
  z-index: 2;
  cursor: pointer;

  @media only screen and ${breakpoint.lg} {
    right: 20px;
  }
`;
