import routes from '@constants/routes.json';

const currentYear = new Date().getFullYear();

export const prepareYearsArray = (range = 10): OptionType[] => {
  const arr = [];
  for (let i = 0; i <= range; i += 1) {
    arr.push({ name: (currentYear + i).toString() });
    arr.push({ name: (currentYear - i).toString() });
  }

  return arr.sort((a, b) => a.name - b.name);
};

export const hasNullValue = (array: Record<string, unknown>[]) =>
  array.some((item) => Object.values(item).some((v) => v === ''));

export const getFullName: (user: User) => string = (user) => `${user.firstName} ${user.lastName}`;

export const prepareLink: (type: 'employer' | 'offer', slug: string, id: number) => string = (type, slug, id) => {
  switch (type) {
    case 'employer':
      return `${routes.employer.source}/${slug || id}`;
    case 'offer':
      return `${routes.offers.source}/${slug || id}`;
    default:
      return '/#';
  }
};

export const isObjectEmpty: (o: Record<string, unknown>) => boolean = (object) => {
  const newObject = { ...object };
  const keys = Object.keys(newObject);

  if (!keys.length) {
    return true;
  }

  keys.forEach((key) => {
    if (!newObject[key]) {
      delete newObject[key];
    }
  });

  return !!Object.keys(newObject).length;
};

export const getKeyByValue: (object: Record<string, unknown>, value: string | string[]) => string = (object, value) => {
  const indexOfS = Object.values(object).indexOf(value as unknown as object);

  const key = Object.keys(object)[indexOfS];

  return key;
};

export const enumToArray = (ENUM, t) =>
  Object.keys(ENUM).map((key) => ({ label: t(ENUM[key], { ns: 'common' }), value: key }));

export const preparePayloadData: (
  object: Record<string, string | number | boolean | null | Record<string, unknown>>
) => Record<string, unknown> = (object) => {
  const newObject: Record<string, unknown> = {};
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      if (object[key] === 'true' || object[key] === 'false') {
        newObject[key] = JSON.parse(object[key].toString());
      } else {
        newObject[key] = object[key];
      }
    }
  });
  return newObject;
};
