import React, { useEffect, useRef, useCallback } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import routes from '@utils/routes';

import search from '@assets/images/ic-search.svg';
import arrow from '@assets/images/chevron.svg';
import LogoMlodzi from '@assets/images/logo-lodz-colour.svg';
import LanguageSelector from '@components/Navbar/LanguageSelector';
import Button, { BUTTON_THEME } from '@components/Button';

import {
  Container,
  InputContainer,
  MobileSearch,
  MobileContentMenu,
  Wrapper,
  DropdownMenu,
  DropmenuItem,
} from '@components/Navbar/MobileMenu/styles';
import { Menu, MenuItem, Grow, IconLink } from '@components/Navbar/styles';

function MobileMenu({ openMenuMobile, items, handlerMenuMobile }) {
  const { t } = useTranslation('navbar');
  const { asPath } = useRouter();

  const mobileMenu = useRef(null);

  useEffect(() => {
    const isOpen = mobileMenu.current.classList.contains('navbar-menu-is-on');
    return isOpen ? document.body.classList.add('navbar-is-open') : document.body.classList.remove('navbar-is-open');
  }, [openMenuMobile]);

  const openDropdown = useCallback((e) => {
    e.target.parentElement.lastChild.classList.toggle('dropdown-on');
    e.target.classList.toggle('arrow-on');
  }, []);

  return (
    <Container
      ref={mobileMenu}
      className={`mobile-menu ${openMenuMobile && 'navbar-menu-is-on'}`}
      isOpen={openMenuMobile}
    >
      <Wrapper>
        <InputContainer>
          <MobileSearch placeholder="Szukaj" />
          <img src={search.src} alt="search" />
        </InputContainer>
        <MobileContentMenu>
          <IconLink href="https://mlodziwlodzi.pl/" target="_blank" rel="noopener noreferrer">
            <Image src={LogoMlodzi} />
          </IconLink>
          <Menu>
            <LanguageSelector />
            {Object.keys(items).map((key) => (
              <MenuItem key={key} active={asPath === routes[key]}>
                <Link href={routes[key]}>
                  <a href={routes[key]} title={t(key)} onClick={handlerMenuMobile}>
                    {t(key)}
                  </a>
                </Link>
                <img src={arrow.src} alt="arrow-menu" onClick={(e) => openDropdown(e)} aria-hidden="true" />
                {items[key].length > 0 ? (
                  <DropdownMenu>
                    {items[key].map((item: NavigationItem) => (
                      <DropmenuItem key={item.id}>
                        <Link href={item.link}>
                          <a href={item.link} target={item.target} title={item.title} onClick={handlerMenuMobile}>
                            {item.title}
                          </a>
                        </Link>
                      </DropmenuItem>
                    ))}
                  </DropdownMenu>
                ) : null}
              </MenuItem>
            ))}
            <Grow />
          </Menu>
        </MobileContentMenu>

        <Button href={routes.signin} buttonTheme={BUTTON_THEME.SECONDARY} onClick={handlerMenuMobile}>
          {t('Signin')}
        </Button>
      </Wrapper>
    </Container>
  );
}

export default MobileMenu;
