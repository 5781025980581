import styled from '@emotion/styled';
import Image from 'next/image';
import { breakpoint } from '@styles/breakpoints';
import { fontSize } from '@styles/globalStyles';

export const LangSelector = styled.button`
  position: relative;
  cursor: pointer;
  margin: 0 20px;
  background: transparent;
  border: none;
  font-size: ${fontSize(13)};

  @media only screen and ${breakpoint.xl} {
    margin: 0 10px;
    font-size: ${fontSize(12)};
  }

  @media only screen and ${breakpoint.lg} {
    margin: 0 5px;
    font-size: ${fontSize(10)};
  }

  @media only screen and ${breakpoint.md} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;

  span {
    margin-right: 5px;
    text-transform: uppercase;
  }

  @media only screen and ${breakpoint.md} {
    padding: 0 8px;
  }
`;

export const ArrowWrapper = styled.div`
  width: 16px;
  height: 16px;

  @media only screen and ${breakpoint.lg} {
    width: 12px;
    height: 12px;
  }

  @media only screen and ${breakpoint.md} {
    width: 16px;
    height: 16px;
  }
`;

export const Arrow = styled(Image)`
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: rotate(180deg);
  }
`;

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin-left: 5px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.navbar.dropdown.background};
  border-radius: 3px;
  box-shadow: 0 4px 4px 0 rgba(0, 35, 48, 0.1);
  top: calc(100% + 20px);
  padding: 25px 0;
  right: 0;

  a {
    padding: 5px 0;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.navbar.dropdown.text};
    text-decoration: none;
    text-transform: uppercase;
  }

  @media only screen and ${breakpoint.md} {
    z-index: 999;
  }
`;
