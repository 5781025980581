/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import App, { AppProps, AppContext } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCookie, removeCookies } from 'cookies-next';
import { get } from 'lodash';

import AuthLayout from '@components/AuthLayout';
import { API, APIRoutes, setApiLocale } from '@utils/api';
import { ThemeProvider } from '@emotion/react';
import theme from '@styles/theme';
import GlobalStyles from '@styles/globalStyles';
import Layout from '@components/Layout';
import AcceptLayout from '@components/AcceptLayout';
import { AppContextProvider } from '@context/AppContext';
import { setItem, KEYS } from '@utils/cache';
import routes from '@constants/routes.json';

import * as gtag from '@utils/gtag';

type MyAppProps = AppProps & {
  mainNavigation: MainNavigation;
  footerNavigation: FooterNavigation;
  referer: string | null;
  userProfile: User;
  isAuthenticated: boolean;
};

const AUTH_ROUTES = [
  routes.signin.destination,
  routes.signup.destination,
  routes.passwordReset.destination,
  routes.passwordChange.destination.split('/').slice(0, -1).join('/'),
  routes.accountConfirmation.destination,
];

const queryClient = new QueryClient();

function MyApp({
  Component,
  pageProps,
  mainNavigation,
  footerNavigation,
  referer,
  userProfile,
  isAuthenticated,
}: MyAppProps) {
  const { route, locale, defaultLocale, events } = useRouter();
  const isAuthPage = useMemo(
    () => AUTH_ROUTES.includes(route) || AUTH_ROUTES.some((authRoute) => route.startsWith(authRoute)),
    [route]
  );
  const isAcceptPage = useMemo(() => route === routes.acceptTerms.destination, [route]);

  useEffect(() => {
    if (isAuthenticated && userProfile) {
      setItem(KEYS.USER_PROFILE, JSON.stringify(userProfile));
    }
  }, [isAuthenticated, userProfile]);

  useEffect(() => {
    setApiLocale(locale || defaultLocale);
  }, [locale, defaultLocale]);

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID);
        ReactPixel.pageView();

        events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });

    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
    };
    events.on('routeChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient} contextSharing>
          <AppContextProvider userProfile={userProfile} isAuthenticated={isAuthenticated}>
            <GlobalStyles />

            {isAuthPage && (
              <AuthLayout referer={referer}>
                <Component {...pageProps} />
              </AuthLayout>
            )}
            {isAcceptPage && (
              <AcceptLayout referer={referer}>
                <Component {...pageProps} />
              </AcceptLayout>
            )}
            {!isAcceptPage && !isAuthPage && (
              <Layout mainNavigation={mainNavigation} footerNavigation={footerNavigation}>
                <Component {...pageProps} />
              </Layout>
            )}
            <ToastContainer
              autoClose={8000}
              position="bottom-center"
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
              closeButton={false}
            />
          </AppContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

const ERROR_PAGES = ['/404', '/500'];

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const currentLang = appContext.ctx.locale;
  const defaultLang = appContext.ctx.defaultLocale;
  const currentPath = appContext.router.route;
  const appProps = await App.getInitialProps(appContext);
  const { req, res } = appContext.ctx;
  API.defaults.headers['Accept-Language'] = currentLang || defaultLang;

  let userProfile: User = null;
  let isAuthenticated = false;
  if (!ERROR_PAGES.includes(appContext.router.route)) {
    // CHECK IF USER IS AUTHENTICATED BASED ON AUTH COOKIE
    const token = getCookie(KEYS.AUTH_TOKEN, { req, res });

    delete API.defaults.headers.common.authorization;
    if (token) {
      API.defaults.headers.common.authorization = token;
    }

    try {
      const {
        data: { user },
      } = await API.get(APIRoutes.me);
      isAuthenticated = true;
      userProfile = user;
    } catch ({ response }) {
      removeCookies(KEYS.AUTH_TOKEN, { req, res });
      isAuthenticated = false;
    }
    if (
      isAuthenticated &&
      userProfile &&
      !(currentPath === routes.acceptTerms.destination) &&
      !(userProfile.rodo && userProfile.termsAndConditions)
    ) {
      const redir = `/${currentLang || defaultLang}${routes.acceptTerms.source}`;
      // console.log(`redirecting from: ${currentPath} to:  ${redir}`)
      if (res) {
        // console.log("302")
        res.writeHead(302, { Location: redir });
        res.end();
      } else {
        // console.log('ROUTER')
        appContext.router.replace(redir);
      }
      // console.log('ENDING');
      return {};
    }
  }

  const {
    data: { navigation },
  } = await API.get(APIRoutes.navigation);

  const mainNavigation = { ...navigation };
  delete mainNavigation.footerMenu;
  delete mainNavigation.internship;

  const footerNavigation = navigation.footerMenu;

  return {
    ...appProps,
    mainNavigation,
    footerNavigation,
    referer: get(appContext, 'ctx.req.headers.referer'),
    userProfile,
    isAuthenticated,
  };
};

export default appWithTranslation(MyApp);
