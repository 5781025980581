const defaultTheme = {
  colors: {
    navbar: {
      dropdown: {
        background: '#005bbb',
        text: '#ffd600',
      },
    },
    borderSeparator: {
      default: '#272727',
    },
    highContrast: {
      primary: '#000',
      secondary: '#ffed00',
    },
    button: {
      primary: {
        default: '#005bbb',
        onHover: '#66c5ee',
        click: '#007fb6',
        disabled: '#dadada',
        text: '#ffffff',
      },
      secondary: {
        default: '#005bbb',
        onHover: '#66c5ee',
        click: '#007fb6',
        disabled: '#dadada',
        background: '#ffffff',
      },
      textButton: {
        default: '#005bbb',
        onHover: '#66c5ee',
        click: '#007fb6',
        disabled: '#dadada',
        background: 'inherit',
      },
    },
    link: {
      primary: '#005bbb',
      onHover: '#66c5ee',
      click: '#007fb6',
    },
    error: '#e6007e',
    offers: {
      internship: '#005f88',
      practice: '#8a004c',
      holiday_internship: '#e6007e',
      mandate: '#005f88',
      b2b: '#8a004c',
      permanent: '#e6007e',
    },
    borders: {
      primary: '#005bbb',
      invalid: '#e6007e',
    },
    navigationLink: {
      default: '#00202d',
      hover: '#005bbb',
    },
  },
  shadow: {
    small: '0 4px 10px 0 rgba(0, 64, 91, 0.1)',
    medium: '4px 8px 20px 0 rgba(0, 64, 91, 0.08)',
  },
  sizes: {
    button: {
      fontSize: {
        small: 11,
        medium: 14,
      },
    },
  },
  defaults: {
    fontFamily: { primary: 'Barlow', secondary: 'Raleway' },
    fontSize: 16,
    mediumFontSize: 18,
    largeFontSize: 20,
  },
  layoutMaxWidth: 1312,
};

export default defaultTheme;
