import React, { useMemo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import footerNavigation from '@constants/footerNavigation.json';
import {
  StyledFooter,
  FooterTop,
  FooterBottom,
  FooterContainer,
  LogoContainer,
  List,
  ListItem,
  EmailAnchor,
  ListItemHeader,
  Buttons,
  PhoneNumber,
  FooterBottomText,
} from '@components/Footer/style';

import Logo from '@assets/images/logo-lodz.svg';

type itemFooterType = {
  id: number;
  title: string;
  link?: string;
};

type contactItemType = itemFooterType & { email?: string };

type ButtonType = {
  id: number;
  link: string;
  image: string;
};

type FooterProps = {
  items: FooterNavigation;
};

function Footer({ items }: FooterProps) {
  const { t } = useTranslation('navbar');
  const { locale } = useRouter();
  const { offices, contact, buttons } = useMemo(() => footerNavigation[locale], [locale]);
  return (
    <StyledFooter>
      <FooterTop>
        <FooterContainer>
          <LogoContainer>
            <Image src={Logo} />
          </LogoContainer>
          <List>
            {items.map((page: itemFooterType) => (
              <Link href={page.link} key={page.id}>
                <ListItem>{page.title}</ListItem>
              </Link>
            ))}
          </List>
          <List className="offices">
            {offices.map((office: itemFooterType) => (
              <Link href={office.link} key={office.id}>
                <ListItem dangerouslySetInnerHTML={{ __html: office.title }} />
              </Link>
            ))}
          </List>
          <List>
            <ListItemHeader>{t('contact')}</ListItemHeader>
            {contact.map((item: contactItemType) => (
              <ListItem key={item.id}>
                {item.email ? (
                  <EmailAnchor href={`mailto:${item.email}`}>{item.title}</EmailAnchor>
                ) : (
                  <PhoneNumber>{item.title}</PhoneNumber>
                )}
              </ListItem>
            ))}
            <Buttons>
              {buttons.map((button: ButtonType) => (
                <a href={button.link} target="_blank" rel="noreferrer" key={button.id}>
                  <img src={button.image} alt="" />
                </a>
              ))}
            </Buttons>
          </List>
        </FooterContainer>
      </FooterTop>
      <FooterBottom>
        <FooterBottomText>
          Усі права захищено | Усі права захищено © Офіс міста Лодзь 2008-{new Date().getFullYear()}
        </FooterBottomText>
      </FooterBottom>
    </StyledFooter>
  );
}

export default Footer;
