import React, { useCallback } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Container, LeftColumn, RightColumn, ScrollableContainer } from '@components/AcceptLayout/styles';

function AcceptLayout({ children, referer }) {
  const router = useRouter();

  const closeClickHandler = useCallback(() => {
    if (!referer) {
      router.push('/');
    } else {
      router.back();
    }
  }, [router, referer]);

  return (
    <Container>
      <LeftColumn />
      <RightColumn className="auth-layout">
        <ScrollableContainer>{children}</ScrollableContainer>
      </RightColumn>
    </Container>
  );
}

export default AcceptLayout;
