import styled from '@emotion/styled';
import { fontSize, ContentWrapper } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const StyledFooter = styled.footer`
  width: 100%;
  color: #f7f7f8;
`;

export const FooterTop = styled.div`
  width: 100%;
  height: 100%;
  background-color: #002330;
  padding: 72px 0 118px 0;
  font-family: ${({ theme }) => theme.defaults.fontFamily.secondary};

  @media screen and ${breakpoint.md} {
    padding: 48px 0;
  }
`;

export const FooterBottom = styled.div`
  background-color: #fff;
  width: 100%;
  color: #002330;
  padding: 25px 0px;

  @media screen and (max-width: 1350px) {
    padding: 25px 15px;
  }
`;

export const FooterContainer = styled(ContentWrapper)`
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  @media screen and ${breakpoint.lg} {
    justify-content: space-around;
  }

  @media screen and ${breakpoint.md} {
    flex-direction: column;
  }
`;

export const LogoContainer = styled.div`
  width: 51px;
  height: 72px;
`;

export const List = styled.div`
  @media screen and ${breakpoint.lg} {
    max-width: 300px;

    &.offices {
      p:first-of-type {
        line-height: 40px;
        margin: 15px 0 -10px 0;
      }
    }
  }

  @media screen and ${breakpoint.md} {
    max-width: unset;
  }
`;

export const ListItem = styled.p`
  margin: 30px 0;
  font-weight: 400;
  font-size: ${fontSize(16)};
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;

  @media screen and ${breakpoint.md} {
    line-height: 32px;
    margin: 20px 0;
  }
`;

export const EmailAnchor = styled.a`
  font-weight: 400;
  font-size: ${fontSize(16)};
  color: rgba(255, 255, 255, 0.7);
  background-color: #002330;
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin: 30px 0;
`;

export const PhoneNumber = styled.span`
  cursor: default;
  color: rgba(255, 255, 255, 0.7);
  margin: 20px 0;
`;

export const ListItemHeader = styled.h2`
  font-size: ${fontSize(16)};
  font-weight: 600;
  margin: 30px 0;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;

  a {
    padding: 3px;
  }
`;

export const TemporaryButton = styled.div`
  width: 20px;
  height: 20px;
  background-color: #f7f7f8;
  margin-right: 25px;
`;

export const FooterBottomText = styled.p`
  color: #002330;
  font-size: ${fontSize(15)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67px;
  letter-spacing: 0.2px;
  font-weight: 300;
  max-width: 1312px;
  margin: 0 auto;
`;
